<!-- 细胞多样性 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText">
            <div>
                T细胞之所以能识别成千上万种威胁，关键是其表面的T细胞受体（TCR），每种T细胞都具有唯一且独特的TCR。TCR基因序列会随着细胞分化成熟而重新排列，从而产生10<sup>15</sup>~10<sup>18</sup>种不同的基因序列组合，由此产生的天文数字的T细胞多样性，使人类的免疫系统能够监控和应对各种各样不同的致病威胁。
            </div>
            <div style="margin-top: 14px;">T细胞多样性深度分析主要从编码TCR基因的角度，总体呈现TCR克隆种类数与TCRβ链VJ基因组合情况。</div>
        </div>


        <div class="globalTitle" style="margin:30px 0 20px 0;color:#FF830F">T细胞受体（TCR）克隆种类数</div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">
                <span style="color: #FFFFFF;">样品中T细胞受体（TCR）克隆种类</span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">TCR克隆种类数</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果</div>
                    <div class="value">2040</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">正常</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">856-2178</div>
                </div>
            </div>
        </div>

        <div class="globalTitle" style="margin:30px 0 20px 0;color:#FF830F">T细胞受体（TCR）克隆Treemap 图 - TCR 克隆种类数可视化展示</div>
        <div class="globalText" style="margin-bottom: 20px;">
            基于T细胞受体(TCR)克隆种类绘制的Treemap图，其每个小色块是一个特异性的T细胞受体(TCR)克隆，其面积代表某一克隆型在本次样品检测中所测到的含量，颜色一致的小色块表示其TCR克隆的氨基酸序列长度一致。通常小色块的数量愈多，面积大小愈均匀，反映免疫多样性愈好，免疫功能储备愈充足。
        </div>

        <div class="navImg" v-viewer>
            <img :src="ossUrl + 'treemap.png'" class="imgTransition" alt="" style="width: 100%;">
        </div>
        <div class="intro">注：图片至多展示前1000个克隆</div>

        <div class="globalTitle" style="margin:30px 0 20px 0;color:#FF830F">T细胞受体（TCR）β链VJ基因组合指数</div>
        <div class="globalText" style="margin-bottom: 20px;">
            T细胞受体（TCR）β链VJ基因组合状态代表了受检者的T细胞群体的多样性，VJ基因的组合类型愈多，代表受检者的TCR多样性丰富，具有较多的淋巴细胞类型，提示受检者免疫机能愈好。
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">
                <span style="color: #FFFFFF;">样品中T细胞受体（TCR）克隆种类</span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">TCR-βVJ基因组合指数</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果</div>
                    <div class="value">6.98</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">正常</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">6.08-7.34</div>
                </div>
            </div>
        </div>

        <div class="globalTitle" style="margin:30px 0 20px 0;color:#FF830F">T细胞受体（TCR）β链VJ基因组合森林图 - VJ基因组合可视化展示</div>
        <div class="globalText" style="margin-bottom: 20px;">
            基于T细胞受体（TCR）β链的VJ基因组合森林图，是从三维空间上分辨检出的各个克隆型在V区与J区中的分布，同时能够直观的看到各克隆型的频率占比。图中X长轴为V区分布，Y短轴为J区分布，Z竖轴为频率百分比。
        </div>
        <div class="navImg" v-viewer>
            <img :src="ossUrl + 'data.png'" class="imgTransition" alt="" style="width: 100%;">
        </div>
    </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl,
        }
    },
    created() {
    },
    mounted() {
        // 置顶
        window.scroll({ top: 0, left: 0 });
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.navImg {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.intro {
    text-align: center;
    font-size: 12px;

    color: #999999;
    line-height: 17px;
    margin-top: 13px;
}

.top {
    width: 100%;
    height: 40px;
    background: #FFAA5B;
    border-radius: 8px 8px 0px 0px;
    padding: 9px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    width: 100%;

    .tip {
        width: 42%;
        background: #FAFCFF;
        padding: 9px 0 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        border-right: none;
        margin-top: -1px;
        display: flex;
        align-items: center;
    }

    .value {
        // width: 191px;
        flex: 1;
        background: #FAFCFF;
        padding: 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        margin-top: -1px;
        display: flex;
        align-items: center;
    }
}
</style>
